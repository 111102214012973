import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import grandeur from "../../Assets/Projects/grandeur.png";
import money from "../../Assets/Projects/money_tracker.png";
import gridguardian from "../../Assets/Projects/gridGuardian.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          {/* Grandeur */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={grandeur}
              isBlog={false}
              title="Grandeur Holidays Nepal"
              description="A robust and maintainable web app for booking luxury holidays. Made with django & mysql stack."
              
              demoLink="https://grandeurholidaysnepal.com"
            />
          </Col>


          {/* Grid Guardian */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gridguardian}
              isBlog={false}
              title="Grid Guardian"
              description="An innovative AI application to predict failures in an electricity distribution transformers in a certain range of location. Made by a team of 3."
              ghLink='https://github.com/NebulaAnish/Grid-Guardian?tab=readme-ov-file'
              demoLink="https://private-user-images.githubusercontent.com/84511488/265320556-c2713a49-bacd-4a53-8d5d-ed1a5ccf9814.gif?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTUiLCJleHAiOjE3MTExOTQyOTUsIm5iZiI6MTcxMTE5Mzk5NSwicGF0aCI6Ii84NDUxMTQ4OC8yNjUzMjA1NTYtYzI3MTNhNDktYmFjZC00YTUzLThkNWQtZWQxYTVjY2Y5ODE0LmdpZj9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFWQ09EWUxTQTUzUFFLNFpBJTJGMjAyNDAzMjMlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjQwMzIzVDExMzk1NVomWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPWI0MjcwODk5MTMxNWFjYzZmNzY4ODQxMjljMjFmNzkzYTZhNzcxOGI1MWQ1ZmUwYzVkYzhlZTNkMWMyNjVkNjYmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0JmFjdG9yX2lkPTAma2V5X2lkPTAmcmVwb19pZD0wIn0.ejZ0sEQLj9zcPVh1fBWFe34jyw75bxPri9pzZiIQGm8"
            />
          </Col>


          {/* Money Tracker */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={money}
              isBlog={false}
              title="Money Tracker"
              description="A django application to maintain a track of your income and expenses. Offers multiple features for efficient money management and various login options. "
              
              demoLink="https://nebulaanish.pythonanywhere.com"
            />
          </Col>

        
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
