import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Balaram Neupane </span>
             from <span className="purple"> Kathmandu, Nepal</span>
            <br />
            I'm a Python Developer. I can write web apps and AI solutions. Although i'm more focused in python, django and AI, I have a knowledge of variety of fields. I started writing code with qbasic and c++ and later jumped into the world of python. I have also done graphic desigining in the past. 
            <br/>
            <br/>
            Apart from coding, I love
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Football
            </li>
            <li className="about-activity">
              <ImPointRight /> Photography
            </li>
            <li className="about-activity">
              <ImPointRight />  Stars and talking about Universe
            </li>
            <li className="about-activity">
              <ImPointRight />  Hiking
            </li>
          </ul>

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">nebula</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
